
import { defineComponent, ref, watchEffect } from "vue";
import Menubar from "primevue/menubar";
import useApi from "@visoma-gmbh/vue-common/api";
import UsersMailSettings from "@/views/users/UsersMailSettings.vue";
import UsersChangePassword from "@/views/users/UsersChangePassword.vue";
import UsersChangeImage from "@/views/users/UsersChangeImage.vue";
import CustomSidebar from "@/components/CustomSidebar.vue";
export default defineComponent({
  components: {CustomSidebar, Menubar, UsersMailSettings, UsersChangePassword, UsersChangeImage },
  setup() {
    const api = useApi()

    const sidebarVisible = ref(false as boolean);
    function openSidebar() {
        return sidebarVisible.value = true;
    }

    const showDropdown = ref(false);
    watchEffect(() => {
      console.log(showDropdown.value)

      if (showDropdown.value == true) {
        console.log('click')
        const greetingButton = document.querySelector('#greeting-dropdown + button') as HTMLElement;
        console.log(greetingButton)
        greetingButton?.click();
      }
    });

    function toggleDropdown() {
      showDropdown.value = !showDropdown.value;
    }

    function getGreeting(): string {
      const date = new Date();
      const hours = date.getHours();

      if (hours < 12) {
          return 'Guten Morgen,';
      } else if (hours < 18) {
          return 'Guten Tag,';
      } else {
          return 'Guten Abend,';
      }
    }

    const items = ref([]);
    const userItems = ref([
      {
        label: "Einstellungen",
        icon: "pi pi-cog",
        command: () => {
          openSidebar()
        },
      },
      {
        label: "Abmelden",
        icon: "pi pi-lock",
        command: () => {
          api.logout()
        },
      },
    ]);
    return {
      api,
      items,
      userItems,
      sidebarVisible,
      openSidebar,
      toggleDropdown,
      getGreeting,
    };
  },
});
