
import { defineComponent, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import useApi from "@visoma-gmbh/vue-common/api";
import FormGroup from "@visoma-gmbh/vue-common/components/FormGroup.vue";
import Message from 'primevue/message';
import { AxiosError } from "axios";
import { useRouter } from "vue-router";
import { ToastSeverity } from "primevue/api";
export default defineComponent({
  components: { FormGroup, Message },
  setup() {
    const router = useRouter();
    const api = useApi();
    const formData = reactive({
      user: "",
      password: "",
    });

    const resetError = ref([] as string[])
    const showResetPassword = ref(false)
    const resetUserid = ref()
    const resetPasswordBusy = ref(false)
    function resetPassword() {
      if (!resetUserid.value) {
        resetError.value = ["Bitte geben Sie eine gültige Benutzer-Nr. ein."]
        return
      }

      resetPasswordBusy.value = true
      api.axios.post("/odata/passwordreset/" + resetUserid.value).finally(() => {
        resetPasswordBusy.value = false
      })
    }

    function showHideResetPassword(show: boolean) {
      showResetPassword.value = show
      resetUserid.value = formData.user
    }

    const rules = {
      user: { required },
      password: { required, min: minLength(3) },
    };

    const $externalResults = ref({});

    const v$ = useVuelidate(rules, formData, { $externalResults });

    return {
      v$,
      formData,
      api,
      showResetPassword,
      showHideResetPassword,
      resetPassword,
      resetPasswordBusy,
      resetError,
      resetUserid,
      submit: function () {
        // Reset $externalResults to allow another request
        $externalResults.value = {};

        // Unset current user
        api.logout()

        v$.value.$validate().then((valid) => {
          if (valid) {
            api.login("/authenticate", {
              id: formData.user,
              password: formData.password,
            })
              .then(() => {
                router.push("/").then(() => {
                  window.location.reload()
                })
              })
              .catch((error: AxiosError) => {
                // Extract error ...
                const message = error.response?.data?.detail
                  ? error.response.data.detail
                  : error;

                // Set errors
                $externalResults.value = {
                  user: [message],
                  password: [message],
                };
              });
          }
        });

        return false;
      },
    };
  },
});
