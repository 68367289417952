
import { defineComponent, inject, ref, Ref, resolveComponent } from "vue";
import FormGroup from "@visoma-gmbh/vue-common/components/FormGroup.vue";
import useApi from "@visoma-gmbh/vue-common/api";
import User from "@/models/User";
import FileUpload from "primevue/fileupload";
import AuthorizedUser from "@/models/AuthorizedUser";
export default defineComponent({
  components: { FormGroup, FileUpload },
  setup() {
    const api = useApi();

    const userIdentity = api.getSession()?.identity as AuthorizedUser;

    function onUploadImage(event: any, userid: number) {
      const formData = new FormData();
      formData.append("image", event.files[0]);
      api.axios
        .post("/odata/users/" + userid + "/image", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          } 
        })
        .then((response) => {
          fetchCurrentUser()
        });
    };

    const file = ref({})
    function onSelectImage (event: any) {
      file.value = event.files[0];
    }

    const user = ref({});
    function fetchCurrentUser() {
      api.axios
        .get("/odata/users/" + userIdentity.id)
        .then((response) => {
          user.value = response.data;
        });
    }
    fetchCurrentUser()

    return {
      api,
      apiurl: inject("apiurl"),
      user,
      file,
      rendered: inject("rendered"),
      onUploadImage,
      onSelectImage,
    };
  },
});
