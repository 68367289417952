
import {computed, defineComponent, inject, onMounted, ref, Ref, watch, watchEffect} from "vue";
import PanelMenu from "primevue/panelmenu";
import useApi from "@visoma-gmbh/vue-common/api";
import AuthorizedUser from '@/models/AuthorizedUser';
import {useWindowSize} from "@vueuse/core";
export default defineComponent({
  components: { PanelMenu },
  setup() {
    const api = useApi()
    const isGuest = inject<Ref<boolean>>('isGuest')
    const user = computed(() => api.getSession()?.identity as AuthorizedUser)

    const items = ref<any[]>([])

    const windowSize = useWindowSize();

    watch(windowSize.width, () => {
      inferItems();
    });

    onMounted(() => {
      inferItems();
    });

    function inferItems(){
      if (windowSize.width.value < 800) {
        items.value = [
          {
            icon: "pi pi-fw pi-home",
            to: "/",
          },
          {
            icon: "pi pi-fw pi-bell",
            to: "/mitteilungen",
            visible: () => isGuest?.value == false && user.value.can("Mitteilungen._main"),
          },
          {
            icon: "pi pi-fw pi-euro",
            to: "/preisvergleiche",
            visible: () => isGuest?.value == false && user.value.can("Preisvergleiche._main"),
          },
          {
            icon: "pi pi-fw pi-file",
            to: "/lieferantenpreislisten",
            visible: () => isGuest?.value == false && user.value.can("Lieferanten._main"),
            items: [
              {
                icon: "pi pi-fw pi-file",
                to: "/lieferantenpreislisten",
              },
              {
                icon: "pi pi-fw pi-list",
                to: "/lieferanten/katalog",
                visible: () => user.value.can("Lieferanten.catalog"),
              },
              {
                icon: "pi pi-fw pi-briefcase",
                to: "/lieferanten/mailing",
                visible: () => user.value.can("Lieferanten.mailing"),
              },
              {
                icon: "pi pi-fw pi-file-excel",
                to: "/lieferanten/single",
                visible: () => user.value.can("Lieferanten.exportcontacts"),
              },
              {
                icon: "pi pi-fw pi-sliders-h",
                to: "/lieferanten/bonuscheck",
                visible: () => user.value.can("Lieferanten.checkbonus"),
              },
            ]
          },
          {
            icon: "pi pi-fw pi-users",
            to: "/mitarbeiter",
            visible: () => isGuest?.value == false && user.value.can("Mitarbeiter._main"),
            items: [
              {
                icon: "pi pi-fw pi-users",
                to: "/mitarbeiter",
              },
              {
                icon: "pi pi-fw pi-map-marker",
                to: "/mitarbeiter/standorte",
              },
              {
                icon: "pi pi-fw pi-flag",
                to: "/mitarbeiter/gesellschafter",
                visible: () => user.value.can("Mitarbeiter.gruppen"),
              },
              // {
              //   label: "Region-Übersicht",
              //   icon: "pi pi-fw pi-tags",
              //   to: "/mitarbeiter/region",
              //   visible: () => user.value.can("Mitarbeiter.region"),
              // },
              // {
              //   label: "Telefonliste (PDF)",
              //   icon: "pi pi-fw pi-chart-bar",
              //   to: "/mitarbeiter/telefonliste",
              //   visible: () => user.value.can("Mitarbeiter.telephone"),
              // },
              {
                icon: "pi pi-fw pi-chart-bar",
                to: "/mitarbeiter/protokoll",
                visible: () => user.value.can("Mitarbeiter.protocol"),
              },
              {
                icon: "pi pi-fw pi-file-excel",
                to: "/mitarbeiter/export",
                visible: () => user.value.can("Mitarbeiter.export"),
              },
            ],
          },

          {
            icon: "pi pi-fw pi-question-circle",
            to: "/abfragen",
            visible: () => isGuest?.value == false && user.value.can("Abfragen._main"),
          },
          {
            icon: "pi pi-fw pi-book",
            to: "/schulungen",
            visible: () => isGuest?.value == false && user.value.can("Schulungen._main"),
            items: [
              {
                icon: "pi pi-fw pi-file",
                to: "/schulungsplan",
                visible: () => user.value.can("Schulungen.export"),
              },
            ],
          },
          // {
          //   icon: "pi pi-fw pi-desktop",
          //   to: "/handeltools",
          //   visible: () => isGuest?.value == false && user.value.can("Tools._main"),
          // },
          // {
          //   label: "Projekte",
          //   icon: "pi pi-fw pi-folder",
          //   to: "/projekte",
          //   visible: () => isGuest?.value == false && user.value.can("Projekte._main"),
          // },
        ]
      }else{
        items.value = [
          {
            label: "Startseite",
            icon: "pi pi-fw pi-home",
            to: "/",
          },
          {
            label: "Mitteilungen",
            icon: "pi pi-fw pi-bell",
            to: "/mitteilungen",
            visible: () => isGuest?.value == false && user.value.can("Mitteilungen._main"),
          },
          {
            label: "Preisvergleiche",
            icon: "pi pi-fw pi-euro",
            to: "/preisvergleiche",
            visible: () => isGuest?.value == false && user.value.can("Preisvergleiche._main"),
          },
          {
            label: "Lieferanten",
            icon: "pi pi-fw pi-file",
            to: "/lieferantenpreislisten",
            visible: () => isGuest?.value == false && user.value.can("Lieferanten._main"),
            items: [
              {
                label: "Lieferanten-Übersicht",
                icon: "pi pi-fw pi-file",
                to: "/lieferantenpreislisten",
              },
              {
                label: "Lieferanten-Katalog abrufen",
                icon: "pi pi-fw pi-list",
                to: "/lieferanten/katalog",
                visible: () => user.value.can("Lieferanten.catalog"),
              },
              {
                label: "Lieferanten-Mailing",
                icon: "pi pi-fw pi-briefcase",
                to: "/lieferanten/mailing",
                visible: () => user.value.can("Lieferanten.mailing"),
              },
              {
                label: "Ansprechpartner Excel-Export",
                icon: "pi pi-fw pi-file-excel",
                to: "/lieferanten/single",
                visible: () => user.value.can("Lieferanten.exportcontacts"),
              },
              {
                label: "Bonuskonditionen Prüfliste",
                icon: "pi pi-fw pi-sliders-h",
                to: "/lieferanten/bonuscheck",
                visible: () => user.value.can("Lieferanten.checkbonus"),
              },
            ]
          },
          {
            label: "Mitarbeiter",
            icon: "pi pi-fw pi-users",
            to: "/mitarbeiter",
            visible: () => isGuest?.value == false && user.value.can("Mitarbeiter._main"),
            items: [
              {
                label: "Mitarbeiter-Übersicht",
                icon: "pi pi-fw pi-users",
                to: "/mitarbeiter",
              },
              {
                label: "Standorte-Übersicht",
                icon: "pi pi-fw pi-map-marker",
                to: "/mitarbeiter/standorte",
              },
              {
                label: "Gesellschafter-Übersicht",
                icon: "pi pi-fw pi-flag",
                to: "/mitarbeiter/gesellschafter",
                visible: () => user.value.can("Mitarbeiter.gruppen"),
              },
              // {
              //   label: "Region-Übersicht",
              //   icon: "pi pi-fw pi-tags",
              //   to: "/mitarbeiter/region",
              //   visible: () => user.value.can("Mitarbeiter.region"),
              // },
              // {
              //   label: "Telefonliste (PDF)",
              //   icon: "pi pi-fw pi-chart-bar",
              //   to: "/mitarbeiter/telefonliste",
              //   visible: () => user.value.can("Mitarbeiter.telephone"),
              // },
              {
                label: "Nutzungsprotokolle",
                icon: "pi pi-fw pi-chart-bar",
                to: "/mitarbeiter/protokoll",
                visible: () => user.value.can("Mitarbeiter.protocol"),
              },
              {
                label: "Excel-Export",
                icon: "pi pi-fw pi-file-excel",
                to: "/mitarbeiter/export",
                visible: () => user.value.can("Mitarbeiter.export"),
              },
            ],
          },

          {
            label: "Abfragen",
            icon: "pi pi-fw pi-question-circle",
            to: "/abfragen",
            visible: () => isGuest?.value == false && user.value.can("Abfragen._main"),
          },
          {
            label: "Schulungen",
            icon: "pi pi-fw pi-book",
            to: "/schulungen",
            visible: () => isGuest?.value == false && user.value.can("Schulungen._main"),
            items: [
              {
                label: "Schulungsplan (PDF/XLS)",
                icon: "pi pi-fw pi-file",
                to: "/schulungsplan",
                visible: () => user.value.can("Schulungen.export"),
              },
            ],
          },
          {
            label: "Änderungshistorie",
            icon: "pi pi-fw pi-history",
            to: "/history",
            visible: () => isGuest?.value == false && user.value.can("Mitarbeiter.viewhistory"),
          },          
        ]
      }
    }

    return {
      items,
    };
  },
});
