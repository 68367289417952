
import { defineComponent, onMounted, provide, ref, watchEffect } from "vue";
import Menubar from "./components/Menubar.vue";
import Navigation from "./components/Navigation.vue";
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import useApi from "@visoma-gmbh/vue-common/api";
import { computed } from "@vue/reactivity";
import { useRouter } from "vue-router";
import LoginForm from "./components/LoginForm.vue";
import useSignalR from "./signalr"
import AuthorizedUser from "./models/AuthorizedUser";
import User from "./models/User";

export default defineComponent({
  components: { Navigation, Menubar, Toast, ConfirmDialog, LoginForm },
  setup() {

    const router = useRouter()
    const api = useApi()

    const signalr = useSignalR()

    // Global rendered state
    const rendered = ref(false);
    provide("rendered", rendered);
    onMounted(() => {
      rendered.value = true;
    });

    // Is guest
    const isGuest = computed(() => {
      if (api.getSession()?.identity == undefined)
        return true;

      const user = api.getSession()?.identity as AuthorizedUser
      if (user.expiredTime && user?.expiredTime < new Date())
       return true;

      return false
    })
    provide("isGuest", isGuest);

    const isLoggedIn = computed(() => {
      return !isGuest.value
    })

    function updatePermisssions() {
      if(isGuest.value == false) {
        api.refreshToken(true);
      }
    }

    // Update permissions on browser refresh (F5) without logout -> login
    updatePermisssions()

    // Update permissions every 15 minutes
    setInterval(() => {
      updatePermisssions()
    }, 15 * 60 * 1000)

    // Forward to login if guest before each route
    router.beforeEach((to, from, next) => {
      if (isGuest.value === true && to.name !== 'Login') {
        next({ name: 'Login' })
        api.logout();   // Logout if expired session
      }
      else if (isGuest.value === false && to.name === 'Login') {
        next({ name: 'Home' })
      } else {
        next()
      }
    })

    // log router changes
    // router.afterEach((to, from) => {
    //   console.log(`Route changed from ${from.name?.toString()} to ${to.name?.toString()}`)
    // })

    // On first load router.beforeEach() is not registered => manual redirect + watch guest
    watchEffect(() => {
      if (isGuest.value && router.currentRoute.value.name !== 'Passwort vergessen') {
        router.push({ name: 'Login' })
      }
    })
    // Show router view component?
    const displayRouterView = computed(() => isGuest.value === false || router.currentRoute.value.name === 'Login' || router.currentRoute.value.name === 'Passwort vergessen')
    return {
      displayRouterView,
      isLoggedIn,
    };
  },
});
