
import { defineComponent, onMounted, onUnmounted } from 'vue';
import Sidebar from "primevue/sidebar";

export default defineComponent({
  name: 'CustomSidebar',
  components: { Sidebar },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    // Method to handle escape key press
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && props.visible) {
        emit('update:visible', false);
      }
    };

    // Add and remove event listeners appropriately
    onMounted(() => {
      document.addEventListener('keydown', handleKeydown);
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown);
    });

    return {};
  }
});
