
import { defineComponent, inject, ref } from "vue";
import FormGroup from "@visoma-gmbh/vue-common/components/FormGroup.vue";
import useApi from "@visoma-gmbh/vue-common/api";
import AuthorizedUser from "@/models/AuthorizedUser";
import useDatatable from "@visoma-gmbh/vue-common/composables/useDatatable";
import Category from "@/models/Category";
export default defineComponent({
  components: { FormGroup },
  setup() {
    const api = useApi();
    class Cat {
      Type?: string
      CategoryId?: number
      SendNotification?: boolean
      Rhythm?: string
      UserId?: number
    }

    const loggedInUser = api.getSession()?.identity as AuthorizedUser;

    const selectedUserMailSettingsNotif = ref([] as string[])
    const selectedUserMailSettingsComments = ref([] as string[])
    const selectedUserMailSettingsConditions = ref([] as string[])
    const loading = ref(false);

    const settingOptions = [
      { name: "nicht benachrichtigen", value: "never" },
      { name: "einmal täglich", value: "daily" },
      { name: "sofort", value: "instant" },
    ]


    const listCats = ref()
    function fetchMailSettings() {
      loading.value = true;

      api.axios.get("/odata/categories")
      .then((response) => {
        listCats.value = response.data.value;

        api.axios
          .get("/odata/users/" + loggedInUser.id, {
            params: {
              $expand: "UserSettings",
            },
          })
          .then((response) => {
            listCats.value.forEach((cat: Category) => {
              if (cat.id) {
                let notif = response.data.userSettings.filter((e: any) => e.categoryId == cat.id && e.type == 'notif')
                let comments = response.data.userSettings.filter((e: any) => e.categoryId == cat.id && e.type == 'comments')
                let conditions = response.data.userSettings.filter((e: any) => e.categoryId == cat.id && e.type == 'conditions')

                selectedUserMailSettingsNotif.value[cat.id] = notif.length && notif[0].rhythm ? notif[0].rhythm : 'daily'
                selectedUserMailSettingsComments.value[cat.id] = comments.length && comments[0].rhythm ? comments[0].rhythm : 'never'
                selectedUserMailSettingsConditions.value[cat.id] = conditions.length && conditions[0].rhythm ? conditions[0].rhythm : 'daily'
              }

            });
          })
          .finally(() => {
            loading.value = false;
          });
      })
    }
    fetchMailSettings()

    function saveMailSettings() {
      let postCats : Cat[] = [];
      selectedUserMailSettingsNotif.value.forEach((e: string, index: number) => {
        const _cat: Cat = {
          UserId: loggedInUser.id,
          Type: 'notif',
          CategoryId: index,
          SendNotification: e == 'never' ? false : true,
          Rhythm: e
        } 
        postCats.push(_cat as never);
      });
      selectedUserMailSettingsComments.value.forEach((e: string, index: number) => {
        const _cat: Cat = {
          UserId: loggedInUser.id,
          Type: 'comments',
          CategoryId: index,
          SendNotification: e == 'never' ? false : true,
          Rhythm: e
        } 
        postCats.push(_cat as never);
      });
      selectedUserMailSettingsConditions.value.forEach((e: string, index: number) => {
        const _cat: Cat = {
          UserId: loggedInUser.id,
          Type: 'conditions',
          CategoryId: index,
          SendNotification: e == 'never' ? false : true,
          Rhythm: e
        } 
        postCats.push(_cat as never);
      });

      api.axios
        .post(
          "/odata/users/" + loggedInUser.id + "/settings", 
          postCats
        )
    }

    return {
      listCats,
      settingOptions,
      selectedUserMailSettingsNotif,
      selectedUserMailSettingsComments,
      selectedUserMailSettingsConditions,
      loading,
      rendered: inject("rendered"),
      submit: function () {
        saveMailSettings();
      },
    };
  },
});
