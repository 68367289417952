
import { defineComponent, inject, ref, Ref, resolveComponent } from "vue";
import FormGroup from "@visoma-gmbh/vue-common/components/FormGroup.vue";
import useApi from "@visoma-gmbh/vue-common/api";
import AuthorizedUser from "@/models/AuthorizedUser";
import { ToastSeverity } from "primevue/api";
import { AxiosError } from "axios";
export default defineComponent({
  components: { FormGroup },
  setup() {
    const api = useApi();
    const identity = api.getSession()?.identity as AuthorizedUser;

    const oldPW = ref("" as string);
    const newPW = ref("" as string);
    const newPWRepeat = ref("" as string);

    function saveNewPassword() {
      // The two new password field don't have the same content
      if (newPW.value != newPWRepeat.value) {
        api.toast.add({
          severity: ToastSeverity.WARN,
          summary: "Fehler",
          detail: "Passwörter stimmen nicht überein.",
          life: 3000,
        });
        return;
      }

      // The same password cannot be chosen again! This also fails in the backend, so we catch it here
      if (newPW.value == oldPW.value) {
        api.toast.add({
          severity: ToastSeverity.WARN,
          summary: "Fehler",
          detail: "Das selbe Passwort kann nicht erneut verwendet werden.",
          life: 3000,
        });
        return;
      }

      // Validate the old password by trying to authenticate with it
      api
        .login("/authenticate", {
          id: identity.id,
          password: oldPW.value,
        })
        .catch((error: AxiosError) => {
          const message = error.response?.data?.detail
            ? error.response.data.detail
            : error;
          return;
        });

      // Now we actually change the password and logout the user.
      api.axios
        .post("/users/" + identity.id + "/password", {
          oldPassword: oldPW.value,
          newPassword: newPW.value,
        })
        .then(() => {
          api.logout();
        });
    }

    return {
      rendered: inject("rendered"),
      oldPW,
      newPW,
      newPWRepeat,
      saveNewPassword,
    };
  },
});
