import 'reflect-metadata';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

// app.scss
import './assets/scss/app.scss';
import './assets/css/theme.css';


import { configureApi } from '@visoma-gmbh/vue-common/api';
import { configureSignalR } from './signalr'
import AuthorizedUser from './models/AuthorizedUser';
import primevue from './primevue';
import { createPinia } from 'pinia';

// Configure api
let BackendUrl = 'https://api-ban-net.visoma.de/';
if (process.env.NODE_ENV === 'production') {
  BackendUrl = 'https://api.ban-net.de/';
}
// BackendUrl = 'https://localhost:7219/';
configureApi(BackendUrl, '1.0', AuthorizedUser)


// SignalR
// configureSignalR('https://api-ban-net.visoma.de/notify')

// Create pinia store
const pinia = createPinia()

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(i18n)

app.provide("apiurl", BackendUrl);


// Add view functions
app.config.globalProperties.append = (path: string, pathToAppend: string) => path + (path.endsWith('/') ? '' : '/') + pathToAppend

// Primevue
primevue(app)

router.isReady().then(() => {
  app.mount('#app')
})
